<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          My Service Request View</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row
      v-if="task.task_type == 'invoice'"
      class="mt-1"
    >
      <b-col cols="12">
        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Invoice Details
          </h4>
        </div>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            {{ task.invoice_item.parent }}
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.invoice_item.parent_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Consultant
          </dt>
          <dd class="col-sm-8">
            <span
              v-for="(option, index) in task.invoice_item.employee_names"
              :key="index"
              class="form-control"
            >
              <span
                :key="index"
                v-text="option"
              />
            </span>
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Invoice #
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.invoice_item.number"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Received Date
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.invoice_item.date"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Invoice Period
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.invoice_item.period"
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <b-row
      v-if="task.task_type == 'timesheet'"
      class="mt-1"
    >
      <b-col cols="12">
        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Timesheet Details
          </h4>
        </div>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Name of Employee Consultant
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.timesheet_item.employee_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Timesheet Frequency
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.timesheet_item.frequency"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Period
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.timesheet_item.period"
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <b-row
      v-if="task.task_type == 'job'"
      class="mt-1"
    >
      <b-col cols="12">
        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Job Details
          </h4>
        </div>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Job Title
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.job_item.job_title"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Vendor
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.job_item.vendor"
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <b-row
      v-if="task.task_type == 'parent'"
      class="mt-1"
    >
      <b-col cols="12">
        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Parent Details
          </h4>
        </div>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            {{ task.parent_item.parent_type }} Name
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.parent_item.parent_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Email
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.parent_item.parent_email"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Phone
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.parent_item.parent_phone"
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <b-row
      v-if="task.task_type == 'interview'"
      class="mt-1"
    >
      <b-col cols="12">
        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Interview Details
          </h4>
        </div>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Employer Name
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.interview_item.employer_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Candidate Name
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.interview_item.candidate_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Interview Date
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.interview_item.date"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Interview Time
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.interview_item.time"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Interviewer Name
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.interview_item.interviewer_name"
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <hr>

    <div class="d-flex mt-2">
      <h4 class="mb-0 ml-50">
        Request Details
      </h4>
    </div>
    <b-row>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Request Name
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.task.name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Status
          </dt>
          <dd class="col-sm-8">
            <span
              class="form-control"
              v-text="task.task.status"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-4 text-right mt-50">
            Request Details
          </dt>
          <dd class="col-sm-8">
            <b-form-textarea
              v-model="task.task.description"
              rows="2"
              disabled
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <hr>

    <div class="d-flex mt-2">
      <h4 class="mb-0 ml-50">
        Comments
      </h4>
    </div>

    <template>
      <div
        class="chat-widget"
        no-body
      >
        <section class="chat-app-window">
          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
          >
            <div class="chats">
              <div
                v-for="(comment, index) in task.comments"
                :key="index"
                class="chat chat-left"
              >
                <div class="chat-avatar mt-2">
                  <b-avatar
                    size="36"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                    src="@/assets/images/avatar-s.jpg"
                  />
                </div>
                <div class="chat-body">
                  <h5 class="chat-name ml-1">{{ comment.user_name }}
                    <span class="chat-date">
                      <small>Commented on {{ comment.date_text }}</small>
                    </span>
                  </h5>
                  <div class="chat-content">
                    <p>{{ comment.comment }}</p>
                  </div>
                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>

          <!-- Message Input -->
          <validation-observer
            ref="simpleRules"
            class="w-100 d-block"
          >
            <b-form class="chat-app-form">
              <validation-provider
                #default="{ errors }"
                name="comment"
                rules="required"
                class="w-100 d-block"
              >
                <b-input-group class="input-group-merge form-send-message">
                  <b-form-input
                    v-model="addRequest.comment"
                    placeholder="Enter your message"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-button
                variant="primary"
                type="submit"
                class="ml-1"
                @click="submitForm"
              >
                Send
              </b-button>
            </b-form>
          </validation-observer>
        </section>
      </div>
    </template>

  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BButton, BFormTextarea, BAvatar, BForm, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Constants from '@/js/constant'
import moment from 'moment'
import Task from '@/js/requests'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,

    VuePerfectScrollbar,
  },
  directives: {
  },
  data() {
    return {
      required,
      task: {},
      addRequest: {
        comment: null,
        date: null,
        time: null,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    }
  },
  mounted() {
  },
  created() {
    Task.getTaskById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.addRequest.date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
          this.addRequest.time = moment().format('hh:mm a')
          Task.updateTaskById(this, this.$route.params.id)
        }
      })
    },
    setTask(task) {
      this.task = task
      this.addRequest.comment = ''
    },
    back() {
      this.$router.push('/requests').catch(() => {})
    },
    requestUpdated(message) {
      this.addRequest.comment = ''
      Task.getTaskById(this, this.$route.params.id)
      this.$swal({
        text: message,
      })
    },
    requestNotUpdated(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
